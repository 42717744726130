<template>
  <div class="wrapper">
    <h2 class="mb-5">
      Emission equivalence
    </h2>

    <v-text-field
      v-model.number="carbon"
      label="CO2"
      type="number"
      class="flex-grow-0"/>
    <div class="pa-2">
      <CyCloudCostManagementEmissionEquivalence :value="carbon"/>
    </div>
    <div class="primary pa-2">
      <CyCloudCostManagementEmissionEquivalence
        dark
        :value="carbon"/>
    </div>
  </div>
</template>

<script>
import CyCloudCostManagementEmissionEquivalence from '@/components/cloud-cost-management/emission-equivalence'

export default {
  name: 'CyCloudCostManagementEmissionEquivalenceSandbox',
  components: {
    CyCloudCostManagementEmissionEquivalence,
  },
  data: () => ({
    carbon: 500,
  }),
}
</script>
