<template>
  <div>
    <h2 class="mb-8">
      TerraCost pricing table
    </h2>

    <v-row>
      <v-col cols="3">
        <CyBtnToggle
          v-model="dataToggle"
          :items="dataToggleItems"/>
      </v-col>

      <v-col cols="3">
        <CyBtnToggle
          v-model="estimateToggle"
          :items="estimateToggleItems"/>
      </v-col>

      <v-col cols="3">
        <CyBtnToggle
          v-model="priorEstimateToggle"
          :items="priorEstimateToggleItems"/>
      </v-col>

      <v-col cols="3">
        <CyBtnToggle
          v-model="unestimateToggle"
          :items="unestimateToggleItems"/>
      </v-col>
    </v-row>

    <hr class="my-8">

    <CyTerracostPricingTable :pricing-data="computedPricingData"/>
  </div>
</template>

<script>
import CyTerracostPricingTable from '@/components/terracost/pricing-table'
import CyBtnToggle from '@/components/btn-toggle'

export default {
  name: 'CyTerracostPricingTableSandbox',
  components: {
    CyBtnToggle,
    CyTerracostPricingTable,
  },
  data: () => ({
    dataToggle: 0,
    dataToggleItems: [
      {
        key: 'with',
        text: 'WITH data',
        icon: 'check',
      },
      {
        key: 'without',
        text: 'NO data',
        icon: 'close',
      },
    ],
    estimateToggle: 0,
    estimateToggleItems: [
      {
        key: 'with',
        text: 'WITH estimate',
        icon: 'check',
      },
      {
        key: 'without',
        text: 'NO estimate',
        icon: 'close',
      },
    ],
    unestimateToggle: 0,
    unestimateToggleItems: [
      {
        key: 'with',
        text: 'WITH unestimated',
        icon: 'check',
      },
      {
        key: 'withou',
        text: 'NO unestimated',
        icon: 'close',
      },
    ],
    priorEstimateToggle: 0,
    priorEstimateToggleItems: [
      {
        key: 'with',
        text: 'WITH prior estimate',
        icon: 'check',
      },
      {
        key: 'without',
        text: 'NO prior estimate',
        icon: 'close',
      },
    ],
    /* mock data */
    pricingData: {
      resource_estimates: [
        {
          type: 'aws_instance',
          address: 'module.wordpress.aws_instance.my_awesome_instance',
          components: [
            {
              label: 'Root volume: Compute',
              prior: {
                cost: '100',
                hourly_cost: '5',
              },
              planned: {
                cost: '60',
                hourly_cost: '4',
              },
            },
          ],
          prior_cost: '100',
          planned_cost: '60',
          prior_hourly_cost: '5',
          planned_hourly_cost: '4',
        },
        {
          type: 'aws_instance',
          address: 'module.magento.aws_instance.my_awesome_instance2',
          components: [
            {
              label: 'Root volume: Compute',
              prior: {
                cost: '100',
                hourly_cost: '5',
              },
              planned: {
                cost: '60',
                hourly_cost: '4',
              },
            },
          ],
          prior_cost: '100',
          planned_cost: '60',
          prior_hourly_cost: '5',
          planned_hourly_cost: '4',
        },
        {
          type: 'db_instance',
          address: 'module.magento.db_instance.my_db_instance',
          components: [
            {
              label: 'Root volume: Storage',
              prior: {
                cost: '30',
                hourly_cost: '2',
              },
              planned: {
                cost: '30',
                hourly_cost: '7',
              },
            },
            {
              label: 'Root volume: Compute',
              prior: {
                cost: '30',
                hourly_cost: '3',
              },
              planned: {
                cost: '90',
                hourly_cost: '1',
              },
            },
          ],
          prior_cost: '60',
          planned_cost: '120',
          prior_hourly_cost: '5',
          planned_hourly_cost: '8',
        },
      ],
      skipped_resource_addresses: [
        'module.magento.skipped_resource_1.resName1',
        'module.magento.skipped_resource_2.resName2',
        'module.magento.skipped_resource_3.resName3',
        'module.magento.skipped_resource_4.resName4',
      ],
      prior_cost: '220',
      planned_cost: '180',
      prior_hourly_cost: '10',
      planned_hourly_cost: '12',
    },
    /* END mock data */
  }),
  computed: {
    computedPricingData () {
      const priorEstimateData = this.priorEstimateToggle
        ? {
            prior_hourly_cost: undefined,
            prior_cost: undefined,
          }
        : {}
      const estimateData = this.estimateToggle
        ? {
            planned_hourly_cost: undefined,
            planned_cost: undefined,
            prior_hourly_cost: undefined,
            prior_cost: undefined,
            resource_estimates: [],
            skipped_resource_addresses: [],
          }
        : {}
      const unestimatedData = this.unestimateToggle
        ? {
            skipped_resource_addresses: [],
          }
        : {}

      return this.dataToggle
        ? {}
        : {
            ...this.pricingData,
            ...unestimatedData,
            ...priorEstimateData,
            ...estimateData,
          }
    },
  },
}
</script>
