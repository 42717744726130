<template>
  <div class="wrapper">
    <h2 class="mb-5">
      Top projects table
    </h2>

    <CyCloudCostManagementTopProjectsTable class="elevation-2"/>
  </div>
</template>

<script>
import CyCloudCostManagementTopProjectsTable from '@/components/cloud-cost-management/top-projects-table'

export default {
  name: 'CyCloudCostManagementTopProjectsTableSandbox',
  components: {
    CyCloudCostManagementTopProjectsTable,
  },
}
</script>

<style lang="scss" scoped>
  .sandbox__cy-cloud-cost-management-top-projects-table {
    max-width: 1200px;
  }
</style>
