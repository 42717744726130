<template>
  <div>
    <h2 class="mb-5">
      Icon picker
    </h2>

    <label class="mb-1">Icon</label>
    <CyInputsIconPicker
      :color-name.sync="colorName"
      :icon.sync="icon"/>
  </div>
</template>

<script>
import CyInputsIconPicker from '@/components/inputs/icon-picker'

export default {
  name: 'CyInputsIconPickerSandbox',
  components: {
    CyInputsIconPicker,
  },
  data: () => ({
    colorName: 'dev',
    icon: 'precision_manufacturing',
  }),
}
</script>
