<template>
  <div class="total-cost-widget">
    <div
      v-if="!loading"
      data-cy="content"
      class="d-flex align-center">
      <div class="total-cost__text flex-shrink-0 mr-12">
        <h3 class="mb-6">
          {{ $t('untranslated.finops') }}
        </h3>
        <div class="subtitle">
          {{ $t('cloudCostManagement.cost') }} ({{ getCurrencySymbol(currency) }})
        </div>
        <div
          data-cy="total-cost"
          class="total__cost mb-3 mt-1">
          <span class="total__cost--number">
            {{ formatAmount(totals.cost) }}
          </span>
          <span class="total__cost--currency">
            {{ getCurrencySymbol(currency) }}
          </span>
          <span class="total__excl-tax subtitle font-weight-regular ml-2">
            {{ $t('cloudCostManagement.exclTax') }}
          </span>
        </div>
        <CyCloudCostManagementTrendChip
          data-cy="trend"
          :value="[totals.oldestCost, totals.latestCost]"/>
        <span
          data-cy="absolute-change"
          class="total__difference subtitle">
          {{ absoluteChange }}
        </span>
      </div>
      <div class="total-cost__chart flex-grow-1">
        <ECharts
          data-cy="chart"
          class="line-chart"
          theme="cycloid"
          autoresize
          :option="options.lineChart"/>
      </div>
    </div>
    <div
      v-else
      data-cy="loader"
      class="sk-template d-flex align-stretch flex-wrap mb-n4">
      <div class="flex-column mr-12 mb-4">
        <div class="sk-block sk-title sk-w-30 sk-h-6 mb-6"/>
        <div class="sk-block sk-title sk-w-10 sk-h-4"/>
        <div class="d-flex align-end mt-1 mb-3">
          <div class="sk-block sk-title sk-w-56 sk-h-12 sk-dark"/>
          <div class="sk-block sk-title sk-w-10 sk-h-4 ml-1"/>
        </div>
        <div class="d-flex align-center">
          <div class="sk-block sk-title sk-w-15 sk-h-6 sk-dark"/>
          <div class="sk-block sk-title sk-w-20 sk-h-4 ml-1"/>
        </div>
      </div>
      <div class="sk-block sk-graph align-self-stretch flex-grow-1 mb-4"/>
    </div>
  </div>
</template>

<script>
import { getCurrencySymbol, formatAmount } from '@/utils/helpers'
import { getTotalsFromAggregateData } from '@/utils/helpers/cloud-cost-management'
import { mapActions, mapState } from 'vuex'
import CyCloudCostManagementTrendChip from '@/components/cloud-cost-management/trend-chip'

export default {
  name: 'CyCloudCostManagementWidgetsTotalCost',
  components: {
    CyCloudCostManagementTrendChip,
  },
  props: {
    currency: {
      type: String,
      default: 'EUR',
    },
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState('organization/cloudCostManagement', {
      aggregateData: (state) => state.aggregateData,
      histogramDates: (state) => state.histogramDates,
    }),
    totals () {
      return getTotalsFromAggregateData(this.aggregateData)
    },
    absoluteChange () {
      const { currency } = this
      return this.formatAmount(this.totals.difference, { currency, signDisplay: 'always' }).replace(/^(.)/, '$1 ')
    },
    options () {
      const providerDatas = _.get(this.aggregateData, 'providerDatas', [])
      const costData = _.map(this.histogramDates, (date) => ({
        cost: _.sumBy(providerDatas, ({ costData }) => _.find(costData, ['date', date])?.cost || 0),
        date,
      }))
      return {
        lineChart: {
          color: '#1C9797',
          grid: {
            top: 12,
            right: 0,
            bottom: 0,
            left: 25,
            containLabel: true,
          },
          series: [
            {
              data: _.map(costData, ({ cost, date }) => [date, cost]),
              symbol: 'none',
              type: 'line',
              stack: 'Total',
              lineStyle: {
                width: 0,
              },
              areaStyle: {
                opacity: 0.45,
              },
            },
          ],
          tooltip: {
            trigger: 'axis',
            formatter: this.formatLineChartTooltip,
          },
          xAxis: {
            axisLabel: {
              formatter: (value) => $date.format(Number(value), 'MMM d'),
            },
            type: 'category',
            boundaryGap: false,
          },
          yAxis: {
            axisLabel: {
              formatter: (value) => this.formatAmount(value, { decimalPlaces: 0 }),
            },
            type: 'value',
            position: 'right',
            splitNumber: 3,
            boundaryGap: [0, 0],
          },
        },
      }
    },
  },
  async created () {
    await this.FETCH_DASHBOARD_DATA({ router: this.$router, persistQuery: false })
    this.loading = false
  },
  methods: {
    ...mapActions('organization/cloudCostManagement', [
      'FETCH_DASHBOARD_DATA',
    ]),
    getCurrencySymbol,
    formatAmount,
    formatLineChartTooltip ([{ data: [date, cost] }]) {
      const { currency } = this
      return `
        <p class='mb-1'>${$date.format(date, 'MMM d, yyyy')}</p>
        <ul class='pl-0' style="list-style: none;">
          <li>${this.$t('untranslated.total')}: <strong>${formatAmount(cost, { currency })}</strong></li>
        </ul>
      `
    },
  },
}
</script>

<style lang="scss" scoped>
.total-cost-widget {
  padding: 16px;
  transition: border-color 0.3s ease;
  border: 1px solid get-color("primary", "light-4");
  border-radius: 8px;
  background: get-color("white");

  &:hover {
    border-color: get-color("primary", "light-2");
  }

  > div {
    flex-wrap: wrap;
  }

  .subtitle {
    color: get-color("primary", "light-3");
    font-size: map.get($font-sizes, "sm");
  }

  .total {
    &__cost {
      font-family: "Roboto Condensed", $font-family-base;

      &--number {
        color: get-color("primary", "light-1");
        font-size: 44px;
        font-weight: $font-weight-bold;
        line-height: 100%;
      }

      &--currency {
        color: get-color("primary", "light-2");
        font-size: map.get($font-sizes, "xl");
        font-weight: $font-weight-bold;
      }
    }

    &__difference {
      opacity: 0;
      font-size: map.get($font-sizes, "base");
    }
  }

  .total-cost {
    &__chart {
      display: flex;
      max-width: 100%;
      padding-right: 16px;

      .line-chart {
        flex-grow: 1;
        width: 200px;
        height: 120px;

        .tooltip-list {
          list-style-type: none;
        }
      }
    }
  }

  .sk-graph {
    min-width: 200px;
    min-height: 120px;
  }
}
</style>
