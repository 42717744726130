<template>
  <div class="mt-4 text-left pa-4 elevation-1">
    <h2 class="mb-3">
      Tags
    </h2>

    <div>
      <h3>Variants</h3>
      <CyTagList
        :tags="[
          { content: 'default', variant: 'default' },
          { content: 'primary', variant: 'primary' },
          { content: 'secondary', variant: 'secondary' },
          { content: 'accent', variant: 'accent' },
          { content: 'success', variant: 'success' },
          { content: 'warning', variant: 'warning' },
          { content: 'error', variant: 'error' },
        ]">
        <template #tag="{ tag }">
          <CyTag
            :variant="tag.variant"
            element-type="button">
            {{ tag.content }}
          </CyTag>
        </template>
      </CyTagList>
    </div>

    <div class="mt-3">
      <h3>Sizes</h3>
      <div>
        <CyTag>
          normal
        </CyTag>
      </div>
      <div class="mt-2">
        <CyTag small>
          small
        </CyTag>
      </div>
    </div>

    <div class="mt-3">
      <h3>Type</h3>
      <p>
        HTML tag can be
        <CyTag element-type="button">
          button
        </CyTag>,
        <CyTag
          href="trololo"
          target="_blank">
          link
        </CyTag>
        or default to <CyTag>
          span
        </CyTag>.
      </p>
    </div>

    <div class="mt-3">
      <h3>Labelled tag</h3>
      <CyTagList
        :tags="[
          { label: 'UUID', content: '881da1d4-6227-11ea-bc55-0242ac130003' },
          { label: 'type', content: 'ephemeral' },
          { label: 'alertname', content: 'CPUUsage' },
        ]"/>
    </div>

    <div class="mt-3">
      <h3>With icon</h3>
      <div>
        <CyTag
          icon-before="fingerprint"
          element-type="button">
          fingerprint
        </CyTag>
        <CyTag
          class="ml-1"
          variant="success"
          icon-before="eco"
          element-type="button">
          eco
        </CyTag>
        <CyTag
          class="ml-1"
          variant="error"
          icon-after="bug_report"
          element-type="button">
          bug_report
        </CyTag>
        <CyTag
          class="ml-1"
          variant="accent"
          icon-after="sports_esports"
          element-type="button">
          sports_esports
        </CyTag>
      </div>

      <div class="mt-1">
        <CyTag
          small
          icon-before="fingerprint"
          element-type="button">
          fingerprint
        </CyTag>
        <CyTag
          small
          class="ml-1"
          variant="success"
          icon-before="eco"
          element-type="button">
          eco
        </CyTag>
        <CyTag
          small
          class="ml-1"
          variant="error"
          icon-after="bug_report"
          element-type="button">
          bug_report
        </CyTag>
        <CyTag
          small
          class="ml-1"
          variant="accent"
          icon-after="sports_esports"
          element-type="button">
          sports_esports
        </CyTag>
      </div>
    </div>

    <div class="light-tag-block mt-6">
      <div>
        <h3 class="white--text">
          Regular list
        </h3>
        <div class="light-tag-block__constrained">
          <CyTagList
            :tags="[
              { content: 'cycloid' },
              { content: 'devops' },
              { content: 'cloud' },
              { content: 'aws' },
              { content: 'kubernetes' },
              { content: 'cluster' },
              { content: 'pipeline' },
              { content: 'infrastructure' },
              { content: 'deployment' },
              { content: 'instance' },
            ]"/>
        </div>
      </div>

      <div class="mt-3">
        <h3 class="white--text">
          Small list
        </h3>
        <CyTagList
          small
          :tags="[
            { content: 'alpha' },
            { content: 'beta' },
            { content: 'gamma' },
          ]"/>
      </div>

      <div class="mt-3">
        <h3 class="white--text">
          Contained list
        </h3>
        <div
          style="width: 400px; max-width: 100%; overflow: auto; background-color: white; resize: horizontal;"
          class="pa-3">
          <CyTagList
            contained
            :tags="[
              { content: 'cycloid' },
              { content: 'devops' },
              { content: 'cloud' },
              { content: 'aws' },
              { content: 'kubernetes' },
              { content: 'cluster' },
              { content: 'pipeline' },
              { content: 'infrastructure' },
              { content: 'deployment' },
              { content: 'instance' },
            ]"/>

          <CyTagList
            class="mt-3"
            contained
            small
            :tags="[
              { content: 'cycloid' },
              { content: 'devops' },
              { content: 'cloud' },
              { content: 'aws' },
              { content: 'kubernetes' },
              { content: 'cluster' },
              { content: 'pipeline' },
              { content: 'infrastructure' },
              { content: 'deployment' },
              { content: 'instance' },
            ]"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CyTagList from '@/components/tag-list'

export default {
  name: 'CyTagSandbox',
  components: {
    CyTagList,
  },
}
</script>

<style lang="scss" scoped>
.light-tag-block {
  padding: 24px;
  background-color: get-color("secondary");

  &__constrained {
    width: 400px;
    max-width: 100%;
  }
}
</style>
