<template>
  <div class="grid grid--favorite-projects">
    <CyAddFavoriteProject/>
  </div>
</template>

<script>
import CyAddFavoriteProject from '@/components/add-favorite-project'

export default {
  name: 'CyAddFavoriteProjectSandbox',
  components: {
    CyAddFavoriteProject,
  },
}
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-gap: 24px;

  &--favorite-projects {
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  }
}
</style>
