var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CyDevDump',{attrs:{"label":"Member data","code":_vm.member}}),_c('div',{staticClass:"ma-4 mb-8"},_vm._l(([
        'email',
        'family_name',
        'given_name',
        'username' ]),function(field){return _c('v-text-field',{key:field,attrs:{"label":field},model:{value:(_vm.member[field]),callback:function ($$v) {_vm.$set(_vm.member, field, $$v)},expression:"member[field]"}})}),1),_c('CyNotification',{attrs:{"title":"Note:","content":"<code>username</code> hides at smaller screen sizes"}}),_c('v-row',_vm._l((_vm.sizes),function(ref){
      var label = ref.label;
      var props = ref.props;
return _c('v-col',{key:label,attrs:{"cols":4}},[_c('h2',{domProps:{"innerHTML":_vm._s(label)}}),_c('section',{staticClass:"d-flex flex-column"},[_c('strong',{staticClass:"subtitle"},[_c('i',[_vm._v("Default")])]),_c('CyMember',_vm._b({attrs:{"member":_vm.member}},'CyMember',props,false))],1),_c('section',{staticClass:"mt-8 d-flex flex-column"},[_c('strong',{staticClass:"subtitle"},[_vm._v(" Simple ")]),_c('CyMember',_vm._b({attrs:{"member":_vm.member,"simple":""}},'CyMember',props,false))],1),_c('section',{staticClass:"mt-8 d-flex flex-column"},[_c('strong',{staticClass:"subtitle"},[_vm._v(" No image ")]),_c('CyMember',_vm._b({attrs:{"member":Object.assign({}, _vm.member, {picture_url: null})}},'CyMember',props,false))],1),_c('section',{staticClass:"mt-8 d-flex flex-column"},[_c('strong',{staticClass:"subtitle"},[_vm._v(" Avatar only ")]),_c('CyMember',_vm._b({attrs:{"member":_vm.member,"avatar-only":""}},'CyMember',props,false))],1),_c('section',{staticClass:"mt-8 d-flex flex-column"},[_c('strong',{staticClass:"subtitle"},[_vm._v(" Missing ")]),_c('CyMember',_vm._b({attrs:{"member":_vm.missingMember}},'CyMember',props,false))],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }