<template>
  <div class="wrapper">
    <h2 class="mb-5">
      Provider data table
    </h2>

    <CyCloudCostManagementProviderDataTable
      provider-canonical="aws"
      class="elevation-2"/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import CyCloudCostManagementProviderDataTable from '@/components/cloud-cost-management/provider-data-table'

export default {
  name: 'CyCloudCostManagementProviderDataTableSandbox',
  components: {
    CyCloudCostManagementProviderDataTable,
  },
  created () {
    this.SET_QUERY_FILTER({ key: 'groupBy', value: ['project', null] })
  },
  methods: {
    ...mapMutations('organization/cloudCostManagement', [
      'SET_QUERY_FILTER',
    ]),
  },
}
</script>

<style lang="scss" scoped>
  .sandbox__cy-cloud-cost-management-provider-data-table {
    max-width: 1200px;
  }
</style>
