<template>
  <div>
    <v-row>
      <v-col cols="auto">
        <CyInfraImportWizardExternalBackend :key="ebKey"/>
      </v-col>
      <v-col lg="3">
        <CyButton @click="reloadComponent">
          Reload component
        </CyButton>
        <CyButton :to="{ name: 'infraImport', params: { orgCanonical } }">
          Go to InfraImport
        </CyButton>
        <v-divider class="my-6"/>
        <h2 class="h5">
          Store data used:
        </h2>
        <dl class="mt-4">
          <template v-for="state in usedStates">
            <dt :key="`dt-${state}`">
              {{ state }}
            </dt>
            <dd :key="`dd-${state}`">
              <CyTag :variant="simulatedStates.includes(state) ? 'default' : 'primary'">
                {{ simulatedStates.includes(state) ? 'Simulated' : 'InfraImport' }}
              </CyTag>
            </dd>
          </template>
        </dl>
        <p class="mt-6">
          Normally the information you enter while going through InfraImport is persisted in the store even after you navigate away (for every step that you've saved). This page will use that data if it exists. Otherwise it will simulate these selections and input:
        </p>
        <dl>
          <dt>Provider</dt>
          <dd>AWS</dd>
          <dt>Provider region</dt>
          <dd>Ireland (<code>eu-west-1</code>)</dd>
          <dt>Provider credential</dt>
          <dd>cycloid-aws</dd>
          <dt>Project name</dt>
          <dd><code>Test project</code></dd>
        </dl>
        <p class="mt-6">
          The data from steps 3, 4 and 5 is not simulated as it's not relevant to the <abbr title="External Backend">EB</abbr> form.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CyInfraImportWizardExternalBackend from '@/components/infra-import/wizard-external-backend.vue'

const simulatedStates = []
const testStates = {
  providerCanonical: 'aws',
  providerConfig: { region: 'eu-west-1', credentialCanonical: 'cycloid-aws' },
  project: {
    name: 'Test project',
    canonical: 'test_project',
    description: null,
    configRepository: {
      branch: 'config',
      canonical: 'cycloid-stacks-test',
      created_at: 1591013782,
      credential_canonical: 'dan-cred',
      default: true,
      id: 307,
      name: 'cycloid-stacks-test',
      updated_at: 1625497583,
      url: 'git@github.com:cycloidio/cycloid-stacks-test.git',
    },
    environment: 'dev',
  },
}
const stateVsMutationMapping = {
  providerCanonical: 'SET_PROVIDER_CANONICAL',
  providerConfig: 'SET_PROVIDER_CONFIG',
  project: 'SET_PROJECT',
}

export default {
  name: 'CyInfraImportWizardExternalBackendSandbox',
  components: {
    CyInfraImportWizardExternalBackend,
  },
  data: () => ({
    ebKey: 0,
    usedStates: _.keys(stateVsMutationMapping),
    simulatedStates: [],
  }),
  beforeCreate () {
    const originalStates = _.pick(
      this.$store.state.organization.infraImport,
      _.keys(stateVsMutationMapping),
    )

    _.each(testStates, (value, key) => {
      if (_.isEmpty(originalStates[key])) {
        this.$store.commit(`organization/infraImport/${stateVsMutationMapping[key]}`, value)
        simulatedStates.push(key)
      }
    })
  },
  created () {
    this.simulatedStates = simulatedStates
  },
  methods: {
    reloadComponent () {
      this.ebKey += 1
    },
  },
}
</script>

<style lang="scss" scoped>
dt {
  font-weight: bold;

  ~ dt {
    margin-top: $spacer * 2;
  }
}
</style>
