<template>
  <div>
    <h2>Button toggles</h2>

    <v-row>
      <v-col>
        <h3 class="accent--text">
          Multi-coloured
        </h3>
        <div
          key="multi-coloured"
          class="mb-5">
          <CyBtnToggle
            v-model="selectedMulti"
            :items="$static.multiColouredItems"/>
        </div>
      </v-col>
      <v-col>
        <h3 class="accent--text">
          With Tooltips
        </h3>
        <div
          key="multi-coloured-with-tooltips"
          class="mb-5">
          <CyBtnToggle
            v-model="selectedMulti"
            :items="_.map($static.multiColouredItems, ({ ...item }) => Object.assign(item, { tooltip: `A tooltip for ${item.text}!` }))"/>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="theme of [, 'primary', 'success', 'warning', 'error', 'paused']"
        :key="theme">
        <h3 class="accent--text">
          {{ _.upperFirst(theme) || 'Default (secondary)' }}
        </h3>
        <div
          v-for="(attrs, index) of $static.examples"
          :key="`${theme}-${index}`"
          class="mb-5">
          <CyBtnToggle
            v-model="selected[index]"
            v-bind="attrs"
            :active-theme="theme"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CyBtnToggle from '@/components/btn-toggle'
import { aws, elasticsearch, flexibleengine } from '@/assets/images'

const base = {
  items: [
    {
      icon: 'accessibility',
      image: aws.logo,
      key: 'aws-cloud-watch',
      text: 'AWS Cloudwatch',
    },
    {
      icon: 'alarm',
      image: elasticsearch.logo,
      key: 'elastic-search',
      text: 'Elastic Search',
    },
  ],
}

export default {
  name: 'CyBtnToggleSandbox',
  components: {
    CyBtnToggle,
  },
  data: () => ({
    selectedMulti: 'add',
    selected: [],
  }),
  computed: {
    $static: () => ({
      examples: [
        {
          label: 'Text and Image',
          items: base.items.map(({ icon, ...item }) => item),
        },
        {
          label: 'Text and Icon',
          items: base.items.map(({ image, ...item }) => item),
        },
        {
          label: 'Text and Icon/Image mix',
          items: base.items.map(({ image, icon, ...item }, index) => ({
            ...item,
            ...(index ? { image } : { icon }),
          })),
        },
        {
          label: 'Icon and Image mix',
          items: base.items.map(({ text, image, icon, ...item }, index) => ({
            ...item,
            ...(index ? { image } : { icon }),
          })),
        },
        {
          label: 'Images only',
          items: base.items.map(({ text, icon, ...item }) => item),
        },
        {
          label: 'Icons only',
          items: base.items.map(({ text, image, ...item }) => item),
        },
        {
          label: 'Text only',
          items: base.items.map(({ image, icon, ...item }) => item),
        },
        {
          label: '3 options',
          items: [
            ...base.items.map(({ text, icon, ...item }) => item),
            {
              image: flexibleengine.logo,
              key: 'flexibleengine',
            },
          ],
        },
        {
          label: 'Using value (String) instead of index (see vue-dev-tools)',
          items: [
            ...base.items.map(({ image, ...item }) => ({ ...item, value: item.key })),
          ],
        },
        {
          label: 'Using value (Number) instead of index (see vue-dev-tools)',
          items: [
            ...base.items.map(({ image, ...item }, index) => ({ ...item, value: index + 42 })),
          ],
        },
      ],
      multiColouredItems: [
        {
          icon: 'add',
          text: 'Add',
          key: 'add',
          value: 'add',
          theme: 'secondary',
        },
        {
          icon: 'edit',
          text: 'Edit',
          key: 'edit',
          value: 'edit',
          theme: 'warning',
        },
        {
          icon: 'delete',
          text: 'Delete',
          key: 'delete',
          value: 'delete',
          theme: 'error',
        },
        {
          icon: 'comment',
          text: 'Comment',
          key: 'comment',
          value: 'comment',
          theme: 'success',
        },
      ],
    }),
  },
}
</script>
