<template>
  <div>
    <div class="toolbar">
      <CyBtnToggle
        v-model="scenario"
        label="Scenarios"
        :items="_.map($static.scenarios, ({ text, value }) => ({ text, key: text, value }))"/>

      <div class="alerts">
        <span class="alerts__title">Trigger global alerts</span>
        <div class="alerts__buttons">
          <div
            v-for="themeName of $static.themes"
            :key="themeName"
            :class="`trigger-alert-group trigger-alert-group--${themeName}`">
            <CyButton
              v-for="({ theme, icon, action, tooltip }, index) in $static.alertBtnTriggers[themeName]"
              :key="`${themeName}:${index}`"
              icon-only
              variant="secondary"
              v-bind="{ title: tooltip, theme, icon }"
              sm
              @click="action"/>
          </div>
        </div>
      </div>
    </div>

    <div class="playground">
      <div class="mock-fields">
        <v-text-field
          v-model="title"
          label="Title"
          class="pa-1"
          clearable/>
        <v-textarea
          v-model="content"
          label="Content"
          class="pa-1 mt-3"
          rows="6"
          clearable/>
        <div class="actions">
          <h4>Helpers</h4>
          <div class="helper">
            <CyButton @click="content = (content || '').concat(` ${$static.linkCode}`).trim()">
              Add link
            </CyButton>
            <CyButton @click="content = (content || '').concat(` ${$static.faker.lorem.paragraph()}`).trim()">
              Add ipsum
            </CyButton>
          </div>
        </div>
      </div>

      <div
        :key="scenario"
        class="examples">
        <h3>Single</h3>

        <CyNotification
          ref="single:info"
          v-bind="{ closeable, buttonLabel }"
          :title="title || 'New version available'"
          :content="content || 'Cycloid v4.0.0 just came out, have a look on the changelog. <a href=#>Read more</a>'"
          @click="onAction"
          @close="onClose"/>

        <CyNotification
          ref="single:error"
          theme="error"
          v-bind="{ closeable, buttonLabel }"
          :title="title || 'Error while saving project'"
          :content="content || 'A project with the same <b>${can}</b> canonical already exists. <a href=#>Read more</a>'"
          @click="onAction"
          @close="onClose"/>

        <CyNotification
          ref="single:success"
          theme="success"
          v-bind="{ closeable, buttonLabel }"
          :title="title || 'Credential added!'"
          :content="content || 'The <b>${name}</b> credential has successfully been created. <a href=#>Read more</a>'"
          @click="onAction"
          @close="onClose"/>

        <CyNotification
          ref="single:warning"
          theme="warning"
          v-bind="{ closeable, buttonLabel }"
          :title="title || 'No running workers'"
          :content="content || 'At least one running worker is required for your jobs to be triggered. <a href=#>Read more</a>'"
          @click="onAction"
          @close="onClose"/>

        <template v-if="!content">
          <h3>List</h3>

          <CyNotification
            ref="list:error"
            theme="error"
            v-bind="{ closeable, buttonLabel }"
            :title="title || 'Invalid parameter value'"
            :content="[
              `git_url in query should match '(?:http(s)|+)$'`,
              `git_url is required. <a href=#>Read more</a>`,
              `git_url requires to be at least 8 characters`,
            ]"
            @click="onAction"
            @close="onClose"/>

          <CyNotification
            ref="list:info"
            v-bind="{ closeable, buttonLabel }"
            :title="title || 'New version available'"
            :content="[
              `git_url in query should match '(?:http|https|git|ssh|git@[-\w.]+)$'`,
              `git_url is required. <a href=#>Read more</a>`,
              `git_url requires to be at least 8 characters`,
            ]"
            @click="onAction"
            @close="onClose"/>

          <CyNotification
            ref="list:success"
            theme="success"
            v-bind="{ closeable, buttonLabel }"
            :title="title || 'Credential added!'"
            :content="[
              `git_url in query should match '(?:http|https|git|ssh|git@[-\w.]+)$'`,
              `git_url is required. <a href=#>Read more</a>`,
              `git_url requires to be at least 8 characters`,
            ]"
            @click="onAction"
            @close="onClose"/>

          <CyNotification
            ref="list:warning"
            theme="warning"
            v-bind="{ closeable, buttonLabel }"
            :title="title || 'No running workers'"
            :content="[
              `git_url in query should match '(?:http|https|git|ssh|git@[-\w.]+)$'`,
              `git_url is required. <a href=#>Read more</a>`,
              `git_url requires to be at least 8 characters`,
            ]"
            @click="onAction"
            @close="onClose"/>

          <h3>API Errors</h3>
          <CyNotification
            ref="api-error:no-details"
            theme="error"
            v-bind="{ closeable, buttonLabel }"
            :title="title || 'No details (single error)'"
            :content="[$static.apiErrors.noDetails[0]]"
            @click="onAction"
            @close="onClose"/>
          <CyNotification
            ref="api-error:has-details"
            theme="error"
            v-bind="{ closeable, buttonLabel }"
            :title="title || 'No details (multiple errors)'"
            :content="$static.apiErrors.noDetails"
            @click="onAction"
            @close="onClose"/>
          <CyNotification
            ref="api-errors:no-details"
            theme="error"
            v-bind="{ closeable, buttonLabel }"
            :title="title || 'Has details (single error)'"
            :content="[$static.apiErrors.hasDetails[0]]"
            @click="onAction"
            @close="onClose"/>
          <CyNotification
            ref="api-errors:has-details"
            theme="error"
            v-bind="{ closeable, buttonLabel }"
            :title="title || 'Has details (multiple errors)'"
            :content="$static.apiErrors.hasDetails"
            @click="onAction"
            @close="onClose"/>

          <h3>App Error</h3>
          <CyNotification
            ref="app-error"
            theme="error"
            :content="[$static.AppError]"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CyBtnToggle from '@/components/btn-toggle'
import { NOTIFICATION_ICONS } from '@/components/notification'
import { faker } from '@faker-js/faker'
import APIResponse from 'mocks/APIResponse'
import { AppError, types as errTypes } from '@/utils/config/errors'

const themes = ['error', 'warning', 'info', 'success']
const linkCode = '<a href="#">Read more</a>'

export default {
  name: 'CyNotificationSandbox',
  components: {
    CyBtnToggle,
  },
  data: () => ({
    title: '',
    content: '',
    scenario: 'simple',
  }),
  computed: {
    $static () {
      const { title, content } = this

      return {
        themes,
        linkCode,
        faker,
        alertBtnTriggers: _.reduce(themes, (acc, type) => {
          const theme = { info: 'secondary' }[type] || type
          return {
            ...acc,
            [type]: [
              {
                theme,
                icon: NOTIFICATION_ICONS[type],
                tooltip: `Custom ${type} alert`,
                action: () => this.SHOW_ALERT({
                  type,
                  title: title || 'Title',
                  content: content || 'this is the amazing content',
                }),
              },
              {
                theme,
                icon: 'mdi-dice-multiple',
                tooltip: `Random ${type} alert`,
                action: () => this.SHOW_ALERT({
                  type,
                  title: faker.random.words(),
                  content: faker.random.words(12),
                }),
              },
              {
                theme,
                icon: 'mdi-image-broken-variant',
                tooltip: `Invalid ${type} alert`,
                action: () => this.SHOW_ALERT({ type }),
              },
            ],
          }
        }, []),
        apiErrors: {
          hasDetails: [
            ...new APIResponse({
              isError: true,
              error: { details: ['first detail', 'second detail'] },
            }).errors,
            ...new APIResponse({
              isError: true,
            }).errors,
          ],
          noDetails: [
            ...new APIResponse({ isError: true }).errors,
            ...new APIResponse({ isError: true }).errors,
          ],
        },
        AppError: AppError.fromError(
          new Error('this is the error'),
          'this is the message',
          'this is the userMessage',
          errTypes.APP,
        ),
        scenarios: [
          {
            text: 'simple',
            value: 'simple',
          },
          {
            text: 'has action',
            value: 'hasAction',
          },
          {
            text: 'is closeable',
            value: 'isCloseable',
          },
          {
            text: 'has action + is closeable',
            value: 'hasActionIsCloseable',
          },
        ],
      }
    },
    closeable () {
      return [
        'hasActionIsCloseable',
        'isCloseable',
      ].includes(this.scenario)
    },
    buttonLabel () {
      return [
        'hasActionIsCloseable',
        'hasAction',
      ].includes(this.scenario)
        ? 'Action'
        : undefined
    },
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    onAction ($event) {
      console.info(`[CyNotification: sandbox] Action was clicked`, $event.target.closest('.cy-notification'))
    },
    onClose ($event) {
      console.info(`[CyNotification: sandbox] Close was clicked`, $event.target.closest('.cy-notification'))
    },
  },
}
</script>

<style lang="scss" scoped>
h3 {
  padding-top: 1em;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 1em;

  > * {
    margin-top: 1em;
    margin-right: 1em;
  }

  .alerts {
    display: flex;
    flex-direction: column;

    &__title {
      padding-bottom: 7px;
      font-size: 12px;
    }

    &__buttons {
      display: flex;
      justify-content: space-around;
      gap: 0.5em;

      .trigger-alert-group {
        $border-radius: 8px;

        @each $theme in 'info', 'success', 'warning', 'error' {
          &--#{$theme} {
            .cy-btn--icon {
              border-radius: 0;

              &:first-child {
                border-top-left-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
                margin-left: 1px !important;
              }

              &:not(:first-child) {
                margin-left: -1px !important;
              }

              &:last-child {
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
              }
            }
          }
        }
      }
    }
  }
}

.playground {
  display: flex;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  .mock-fields {
    width: 50%;
  }

  .examples {
    margin: 0 auto;

    > .cy-notification {
      width: 512px;
    }
  }
}
</style>
