export default {
  CyAddFavoriteProject: 'https://www.figma.com/file/Hu3yInQNh3j28Bg6UEz2sM/Dashboard?node-id=293%3A23226',
  CyBetaTag: 'https://www.figma.com/file/ijs1fHkcMb3Q9AqKwiTQ7u/Component-library?node-id=6%3A9475',
  CyBtnToggle: 'https://www.figma.com/file/ijs1fHkcMb3Q9AqKwiTQ7u/Component-library?node-id=6%3A2292',
  CyButton: 'https://www.figma.com/file/ijs1fHkcMb3Q9AqKwiTQ7u/Component-library?node-id=4%3A685',
  CyDataTablePagination: 'https://www.figma.com/file/MeaLtCU8E4zowwQIsuLuxk/Pagination-%3E-Input?node-id=7%3A5419',
  CyInfraImportWizardExternalBackend: 'https://www.figma.com/file/5xMkXOSGkCLb5lPWpDwaUW/Infra-Import?node-id=701%3A6149',
  CyKeyNumbersCard: 'https://www.figma.com/file/Hu3yInQNh3j28Bg6UEz2sM/Dashboard?node-id=293%3A22664',
  CyKpiWidgetsDashboardWidget: 'https://www.figma.com/file/ijs1fHkcMb3Q9AqKwiTQ7u/Component-library?node-id=8%3A17526',
  CyNotification: 'https://www.figma.com/file/ijs1fHkcMb3Q9AqKwiTQ7u/Component-library?node-id=8%3A16679',
  CyProjectMiniCard: 'https://www.figma.com/file/Hu3yInQNh3j28Bg6UEz2sM/Dashboard?node-id=253%3A30829',
  CyTag: 'https://www.figma.com/file/ijs1fHkcMb3Q9AqKwiTQ7u/Component-library?node-id=6%3A9293',
}
