<template>
  <div>
    <v-data-iterator
      class="pagination-sandbox rounded"
      :items="$static.items"
      :options.sync="options"
      hide-default-footer>
      <template #default="{ items }">
        <v-card>
          <v-list>
            <v-list-item
              v-for="(item, index) in items"
              :key="index">
              {{ item }}
            </v-list-item>
          </v-list>
        </v-card>
      </template>
      <template #footer>
        <CyDataTablePagination
          v-if="$static.items.length"
          class="v-data-footer px-4 py-1"
          :items-length="$static.items.length"
          :items-per-page-options="[5, 10, 20, 50]"
          :options.sync="options"/>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import CyDataTablePagination from '@/components/data-table/pagination'

export default {
  name: 'CyDataTablePaginationSandbox',
  components: {
    CyDataTablePagination,
  },
  data: () => ({
    options: {
      itemsPerPage: 5,
      page: 1,
    },
  }),
  computed: {
    $static: () => ({
      items: [
        'Jerde',
        'Bailey',
        'Jakubowski',
        'Stokes',
        'Langworth',
        'Streich',
        'Leffler',
        'Sawayn',
        'King',
        'Grady',
        'Balistreri',
        'Mertz',
        'Upton',
        'Smith',
        'Jacobson',
        'Braun',
        'Mayert',
        'Koch',
        'Cruickshank',
        'Rath',
        'Greenholt',
        'VonRueden',
        'West',
        'Hermiston',
        'McKenzie',
      ],
    }),
  },
}
</script>

<style lang="scss" scoped>
.pagination-sandbox {
  background-color: map.get($grey, "light-5");
}
</style>
